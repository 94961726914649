@import '~ngx-toastr/toastr';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body {
    font-family: Arial;
    color: #000000DE;
    margin: 0px !important;
}

a,
button {
    cursor: pointer;
}

h1 {
    color: #2B39E2;
    font-size: 20px !important;
    font-weight: 500 !important;
}

#header {
    display: flex;
    justify-content: space-around;
    align-items: center
}

#header > h1 {
    margin: 15px !important;
}

#header > img:first-child {
    display: none;
    margin-left: 1rem;
}

#header > img {
    height: 75px;
    padding: 10px;
}

#main {
    background-color: #EFEFEF8E;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#container {
    width: 100%;
}

#container > h1 {
    text-align: center;
}

#login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

#login-form h2 {
    margin-bottom: 0px;
    font-weight: 400 !important;
}

#login-form label {
    font-weight: lighter;
}

#login-form input {
    background-color: #EBEBEB;
    border: none;
    padding: 20px;
    margin-top: 25px;
    color: #00000099;
    border-radius: 2px;
}

#login-form > div > input {
    width: -webkit-fill-available;
    width: -moz-available;
}

#login-form > div > button {
    margin-top: 35px;
    position: absolute;
    margin-left: -40px;
    background: transparent;
    color: #00000099;
}

#login-form button {
    background-color: #1462A5;
    padding: 10px;
    border: none;
    border-radius: 3px;
    color: white;
    font-weight: bolder;
}

.btn-transparent {
    background: none;
    border: none;
    color: #00000099;
    font-weight: 700;
}

.btn-blue,
.btn-red {
    padding: 10px;
    border: none;
    border-radius: 3px;
    color: white;
    font-weight: bolder;
    width: fit-content;
    margin: 0px auto;
}

.btn-blue {
    background-color: #2B39E2;
}

.btn-red {
    background-color: #e22b2b;
}

.btn-l-blue {
    background: none;
    border: none;
    color: #2B39E2;
    font-weight: 700;
    width: 40px;
}

#login-form label,
#login-form button {
    margin-top: 20px;
}

@media screen and (min-width: 910px) {
    #header > img:first-child {
        display: block;
    }

    #login-form {
        max-width: 300px;
        width: 50vw;
    }

    #main {
        justify-content: center;
    }

    #main > div > h1:first-child {
        display: none;
    }

    #container {
        padding: 60px;
        background: white;
        border-radius: 5px;
        width: auto;
    }
}

.little {
    color: #00000099;
    text-decoration: underline;
    font-size: 12px;
}

.icon-input {
    margin-top: 42px;
    position: absolute;
    margin-left: 20px;
    background: transparent;
    color: #00000099;
}

.icon-input + input {
    padding-left: 40px !important;
}

.container-white {
    padding: 30px 20px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;
}

#table-container {
    overflow: auto;
    background-color: white;
    border-radius: 4px;
}

#table-container table {
    min-width: 100%;
    width: auto;
    height: auto;
    border-spacing: 0px;
}

#table-container table td,
#table-container table th {
    text-align: left;
    min-width: 100px;
}

#table-container table tr td:first-child,
#table-container table tr th:first-child {
    padding-left: 40px;
}

#table-container table th {
    position: sticky;
    top: 0;
    background-color: white;
    font-size: 12px;
    padding: 20px 12px;
}

#table-container table td {
    padding: 30px 12px;
    vertical-align: initial;
}

#table-container table tbody tr {
    border-top: 1px solid black;
}

#table-container table td span  {
    display: block;
    width: fit-content;
    margin: 0;
    white-space: nowrap;
    text-align: left;
}

#table-container table tbody tr:not(:last-child) td {
    border-bottom: 1px solid #DEDEDE;
}

#table-container table td.actions {
    display: flex;
    justify-content: flex-end;
}

#table-container table td.actions button.icon {
    display: inline-block;
    padding: 8px;
}

#table-container table td.actions button.icon:not(:last-child) {
    margin-right: 5px;
}

/* searcher */

#searcher{
    background-color: white;
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 12px !important;
    margin-bottom: 5px;
    overflow-x: auto;
    flex-shrink: 0;
}



/* filter */

#filter {
    display: block;
    background-color: white;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 12px !important;
    margin-bottom: 20px;
    overflow-x: auto;
    flex-shrink: 0;
}

#filter form > *:not(:last-child),
#filter .paginator > *:not(:last-child),
#filter .filters > *:not(:last-child) {
    margin-right: 10px;
}

#filter form {
    display: flex;
    align-items: center;
    min-width: 100%;
    width: auto;
    justify-content: flex-end;
}

#filter form .auto {
    flex-grow: 1;
}

#filter input, #filter span, #filter select {
    font-size: 13px;
    padding: 5px 0px;
}

#filter .paginator, 
#filter .filters {
    display: flex;
    align-items: center;
}

#filter .paginator button,
#filter .filters button {
    padding: 2px 10px;
    background-color: transparent;
    color: #707070;
    border: none;
}

#filter .paginator button i,
#filter .filters button i {
    margin: unset;
}

#filter .paginator .items-per-page select,
#filter .filters .items-per-page select {
    margin-left: 5px;
    border: none;
}


/**
 *  DIALOGS
 */
 .dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.dialog {
    padding: 30px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    width: 500px;
    max-height: 550px;
    overflow-y: auto;
}

.dialog.html {
    width: 700px;
}

.dialog .dialog-content {
    flex-grow: 1;
}

.dialog .field {
    display: block;
}

.dialog .field:not(:last-child) {
    margin-bottom: 20px;
}

.dialog .field label {
    font-weight: 700;
    display: block;
    margin-bottom: 2px;
}

.dialog .field input,
.dialog .field select {
    display: block;
    width: 100%;
}

.dialog h2 {
    margin: 0 0 10px;
}

.dialog p.subtitle {
    margin: 0 0 30px;
}

.dialog .section.buttons {
    display: flex;
    margin: 20px 0px 0px;
    justify-content: flex-end;
}

.dialog .section.buttons button {
    margin-left: 10px;
}

.modal-loading {
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 70%);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
}

.fa-spinner {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.modal-loading > div {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: .3rem;
    padding: 30px;
}

.modal-loading > div > i {
    animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}